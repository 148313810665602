import * as React from "react";
import type { SVGProps } from "react";
const SvgCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={32}
    fill="none"
    viewBox="0 0 34 32"
    {...props}
  >
    <g clipPath="url(#b771e306450c59710865346134b685f4018e6ebd-a)">
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.901}
        d="M11.665 30.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM27.667 30.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM30.668 23.335H12.052a3.335 3.335 0 0 1-3.265-2.662L4.94 2A1.67 1.67 0 0 0 3.31.668H.668"
      />
      <path
        fill="currentcolor"
        d="M5.758 4h25.91c1.034 0 1.818.933 1.642 1.952l-2.087 12a1.67 1.67 0 0 1-1.642 1.381H8.424"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.901}
        d="M5.758 4h25.91c1.034 0 1.818.933 1.642 1.952l-2.087 12a1.67 1.67 0 0 1-1.642 1.381H8.424"
      />
    </g>
    <defs>
      <clipPath id="b771e306450c59710865346134b685f4018e6ebd-a">
        <path fill="#fff" d="M0 0h34v31.333H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCart;
