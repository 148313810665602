import React, { FunctionComponent, MouseEvent } from 'react'
import styled from 'styled-components'
import SvgHorizontalLogo from '../generated/svg/horizontal-logo'
import SvgCart from '../generated/svg/cart'
import SvgPerson from '../generated/svg/person'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'
import { Link } from './link'

const Outer = styled.div`
  display: flex;
  gap: 1.5rem;
`

const Item = styled(Link)`
display: block;
  font-size: 0.8125rem;
  line-height: normal;
  letter-spacing: 0.08em;
  font-weight:700;
  padding: 0.5rem;
`

const Icon = styled(Link)`
display: block;
  margin-left: 1rem;
  place-content: center;
  & + & {
    margin-left: 0;
  }
  svg {
    display: block;
    height: 2rem;
    width: auto;
  }
`

type NavProps = {

}
export const Nav: FunctionComponent<NavProps> = () => {
  const scroll = (e: MouseEvent<HTMLAnchorElement>) => {
    if(location.pathname === site_path_root){
      e.preventDefault()
      querySelectorWithShadow(e.currentTarget.hash)?.scrollIntoView({behavior: 'smooth'})
    }
  }
  return <Outer>
    <Item href="/#about" onClickCapture={scroll}>「まめとくらす」とは?</Item>
    <Item href="/#products" onClickCapture={scroll}>商品について</Item>
    <Item href="/#howto" onClickCapture={scroll}>ご利用方法</Item>
    <Item href="/#order" onClickCapture={scroll}>ご注文</Item>
    <Item href="/Page/faq.aspx">よくある質問</Item>
    <Icon href="/Form/Order/CartList.aspx">
      <SvgCart />
    </Icon>
    <Icon href="/Form/User/MyPage.aspx">
      <SvgPerson />
    </Icon>
  </Outer>
}