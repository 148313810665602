import React, { FunctionComponent, MouseEvent } from 'react'
import styled from 'styled-components'
import SvgPerson from '../generated/svg/person'
import SvgCart from '../generated/svg/cart'
import { SvgRightAngle } from './svg'
import classNames from 'classnames'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'
import { Link } from './link'

const Outer = styled.div`
background-color: var(--beige);
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
place-content: center;
z-index: 100;
transition: opacity 0.4s, transform 0.4s;
&.hide {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1rem);
}
`

const Inner = styled.div`
padding: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const Button = styled(Link)`
display: block;
border-radius: 100rem;
display: grid;
grid-template-columns: 1fr auto 1fr;
font-size: 0.9375rem;
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
border-radius: 100rem;
background-color: var(--color);
color: white;
padding: 0.8125rem 1.3125rem;
align-items: center;
transition: opacity 0.4s;
&:hover {
  opacity: 0.75;
}
svg {
  display: block;
  width: auto;
  height: 1.75rem;
}
`

const List = styled.div`
margin-top: 1.75rem;
`

const Item = styled(Link)`
display: block;
padding: 1.125rem 0.5625rem;
font-size: 0.9375rem;
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
display: flex;
justify-content: space-between;
align-items: center;
& + & {
  border-top: 1px solid currentColor;
}
svg {
  display: block;
  height: 0.8125rem;
  width: auto;
}
`


type MenuProps = {
  open: boolean,
  onClose: () => void
}
export const Menu: FunctionComponent<MenuProps> = ({open, onClose}) => {
  const scroll = (e: MouseEvent<HTMLAnchorElement>) => {
    if(location.pathname === '/'){
      e.preventDefault()
      querySelectorWithShadow(e.currentTarget.hash)?.scrollIntoView({behavior: 'smooth'})
      onClose()
    }
  }
  return <Outer className={classNames({hide: !open})}>
    <Inner>
      <ButtonContainer>
        <Button style={{'--color': 'var(--green)'}} href="/Form/User/MyPage.aspx">
          <SvgPerson />
          マイページ
        </Button>
        <Button style={{'--color': 'var(--orange)'}} href="/Form/Order/CartList.aspx">
          <SvgCart />
          ショッピングカート
        </Button>
      </ButtonContainer>
      <List>
        <Item href="/#about" onClick={scroll}>
          「まめとくらす」とは?
          <SvgRightAngle size={8}/>
        </Item>
        <Item href="/#products" onClick={scroll}>
          商品について
          <SvgRightAngle size={8}/>
        </Item>
        <Item href="/#howto" onClick={scroll}>
          ご利用方法
          <SvgRightAngle size={8}/>
        </Item>
        <Item href="/#order" onClick={scroll}>
          ご注文
          <SvgRightAngle size={8}/>
        </Item>
        <Item href="/Page/faq.aspx" onClick={scroll}>
          よくある質問
          <SvgRightAngle size={8}/>
        </Item>
      </List>
    </Inner>
  </Outer>
}