import * as React from "react";
import type { SVGProps } from "react";
const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} stroke="currentcolor" />
    <mask
      id="e25052618627ffbdb0e4f4f2ae300086d8c998a9-a"
      width={32}
      height={32}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={16} cy={16} r={15.5} fill="#fff" stroke="#6B3529" />
    </mask>
    <g mask="url(#e25052618627ffbdb0e4f4f2ae300086d8c998a9-a)">
      <ellipse
        cx={16.001}
        cy={26.182}
        fill="currentcolor"
        rx={13.091}
        ry={8.727}
      />
    </g>
    <circle cx={16} cy={10.181} r={5.818} fill="currentcolor" />
  </svg>
);
export default SvgPerson;
